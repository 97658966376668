import { FC } from "react";

import "./Nav.css";

const TopBar: FC = () => {

    return(
        <nav className="topBar">
          
        </nav>
    );
}

export default TopBar;
