
export const flattenSimpleArray = (arrayName: any[]) => {

   return arrayName.map((array) => {

        return array[0]
    })

}

