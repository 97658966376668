
import { useNavigate } from "react-router-dom";

const ProfileIcon = () => {

    const navigate = useNavigate();

    const navigateToEditProfile = () => {

        navigate("/profile/edit/")

    }

    return(
        
        <>
        <svg onClick={navigateToEditProfile} className="sidebar_icon svg_hoverable" width="35" height="35" viewBox="0 0 24 24" fill="#002233" xmlns="http://www.w3.org/2000/svg">
            <path className="sidebar_icon svg_hoverable" d="M12 2.85C13.74 2.85 15.15 4.26 15.15 6C15.15 7.74 13.74 9.15 12 9.15C10.26 9.15 8.85 7.74 8.85 6C8.85 4.26 10.26 2.85 12 2.85ZM12 16.35C16.455 16.35 21.15 18.54 21.15 19.5V21.15H2.85V19.5C2.85 18.54 7.545 16.35 12 16.35ZM12 0C8.685 0 6 2.685 6 6C6 9.315 8.685 12 12 12C15.315 12 18 9.315 18 6C18 2.685 15.315 0 12 0ZM12 13.5C7.995 13.5 0 15.51 0 19.5V24H24V19.5C24 15.51 16.005 13.5 12 13.5Z" fill="#002233"/>
            <path className="sidebar_icon svg_hoverable" d="M21.15 21.65H21.65V21.15V19.5C21.65 19.1895 21.4713 18.9317 21.3106 18.7539C21.1354 18.5599 20.8978 18.3695 20.6244 18.187C20.0748 17.82 19.3129 17.4407 18.4256 17.0985C16.6506 16.4139 14.3016 15.85 12 15.85C9.69837 15.85 7.3494 16.4139 5.57445 17.0985C4.6871 17.4407 3.92525 17.82 3.37559 18.187C3.10222 18.3695 2.86456 18.5599 2.6894 18.7539C2.52873 18.9317 2.35 19.1895 2.35 19.5V21.15V21.65H2.85H21.15ZM15.65 6C15.65 3.98386 14.0161 2.35 12 2.35C9.98386 2.35 8.35 3.98386 8.35 6C8.35 8.01614 9.98386 9.65 12 9.65C14.0161 9.65 15.65 8.01614 15.65 6ZM6.5 6C6.5 2.96114 8.96114 0.5 12 0.5C15.0389 0.5 17.5 2.96114 17.5 6C17.5 9.03886 15.0389 11.5 12 11.5C8.96114 11.5 6.5 9.03886 6.5 6ZM0.5 19.5C0.5 18.6861 0.903724 17.9363 1.65091 17.2466C2.40149 16.5539 3.46489 15.9563 4.68406 15.4679C7.12321 14.4908 10.06 14 12 14C13.94 14 16.8768 14.4908 19.3159 15.4679C20.5351 15.9563 21.5985 16.5539 22.3491 17.2466C23.0963 17.9363 23.5 18.6861 23.5 19.5V23.5H0.5V19.5Z" stroke="none"/>
        </svg>
        <span className="sidebar_text"  onClick={navigateToEditProfile}><strong>Edit Profile</strong></span>

        </>
    )

}

export default ProfileIcon;

